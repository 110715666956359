<template>
    <Content/>
</template>

<script>
import Content from "./Content"
export default {
    components: {
        Content
    }
}
</script>